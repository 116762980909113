import { BrowserCategory } from "./types";

export const getBrowserCategory: () => BrowserCategory = () => {
  const isIe = /*@cc_on!@*/ false || !!document.documentMode;
  if (isIe) {
    return BrowserCategory.Unusable;
  }

  const isNonChromiumEdge = !!window.StyleMedia;
  if (isNonChromiumEdge) {
    return BrowserCategory.Unusable;
  }

  const isChrome = !!window.chrome;
  const isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

  if (isEdgeChromium) {
    return BrowserCategory.Supported;
  }

  if (isChrome) {
    return BrowserCategory.Supported;
  }

  return BrowserCategory.Unsupported;
};

export const getBrowserUnsupportMessage = (
  browserCategory: BrowserCategory.Unusable | BrowserCategory.Unsupported
): string => {
  const unusableMessage = "Denna webbläsare stöds tyvärr inte.";
  const unsupportedMessage = "Använd en modern version av Chrome eller Edge för bästa upplevelse.";

  switch (browserCategory) {
    case BrowserCategory.Unusable: {
      return `${unusableMessage}\n${unsupportedMessage}`;
    }
    case BrowserCategory.Unsupported: {
      return unsupportedMessage;
    }
  }
};
