import "./polyfill";

import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./styling/style.css";
import { webRootNodeId, webRootNodeClassName } from "./constants";
import { getBrowserCategory, getBrowserUnsupportMessage } from "@helpers/misc/browserSupport";
import { WarningNotice } from "@components/elements/warningNotice/warningNotice";
import { BrowserCategory } from "@helpers/misc/types";

const browserCategory = getBrowserCategory();
console && console.log && console.log("Browser Category:", browserCategory);

if (browserCategory == BrowserCategory.Unusable) {
  const browserMessage = getBrowserUnsupportMessage(browserCategory);
  const noticeRoot = document.createElement("div");
  document.body.appendChild(noticeRoot);
  ReactDOM.render(<WarningNotice message={browserMessage} />, noticeRoot);
} else {
  let root = document.getElementById("root");
  if (root == null) {
    root = document.createElement("div");
    root.id = webRootNodeId;
    root.className = webRootNodeClassName;
    document.body.appendChild(root);
  }

  import("./appRoot").then(({ AppRoot }) => {
    ReactDOM.render(<AppRoot />, root);
  });
}
