import React, { FC, CSSProperties } from "react";
import { WarningNoticeProps } from "./types";

// This component is used for IE warning, which means we can not use our style system.
// Instead we have to use plain react.

const containerStyle: CSSProperties = {
  backgroundColor: "#fff3cd",
  borderBottomColor: "#ffeeba",
  borderBottomStyle: "solid",
  borderBottomWidth: "1px",
  color: "#856404",
  fontFamily: `"Skanska Sans Pro", "Segoe UI Semilight", "Segoe UI", "Segoe", "Tahoma", "Helvetica", "Arial", "sans-serif"`,
  fontSize: "16.57px",
  letterSpacing: "0.25px",
  lineHeight: "22px",
  padding: ".75rem 1.25rem",
  textAlign: "center",
  whiteSpace: "pre-wrap",
};

export const WarningNotice: FC<WarningNoticeProps> = ({ message }) => {
  return <div style={containerStyle}>{message}</div>;
};
